import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();

function classToggle() {
  const nav = document.querySelector(".Navbar__Items");
  nav.classList.toggle("Navbar__ToggleShow");
  toggleMenuState();
}

function toggleMenuState() {
  const icon = document.querySelector(".Navbar__Link-toggle i");
  const nav = document.querySelector(".Navbar__Items");

  if (nav.classList.contains("Navbar__ToggleShow")) {
    icon.classList.add("toggle");
  } else {
    icon.classList.remove("toggle");
  }
}

// Hide menu when a link is clicked
function handleMenuLinkClick(event) {
  const clickedLink = event.target.closest("a");
  if (clickedLink) {
    const nav = document.querySelector(".Navbar__Items");
    nav.classList.remove("Navbar__ToggleShow");
    toggleMenuState();
  }
}

// Event listener for the menu button toggle
document
  .querySelector(".Navbar__Link-toggle")
  .addEventListener("click", classToggle);

// Event listener for clicking on a menu link
document
  .querySelector(".Navbar__Items")
  .addEventListener("click", handleMenuLinkClick);
