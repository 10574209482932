const prefersReducedMotion = window.matchMedia(
  "(prefers-reduced-motion: reduce)"
).matches;

// Default values for defaultProps
export const defaultProps = {
  easing: "cubic-bezier(0.5, 0, 0, 1)",
  distance: prefersReducedMotion ? "0px" : "30px",
  duration: prefersReducedMotion ? 0 : 1000,
  desktop: true,
  mobile: true,
};

// Target elements with conditional animations
// If the user has reduced motion settings set to true, all elements will be rendered without animation
export const targetElements = [
  {
    element: ".section-title",
    animation: prefersReducedMotion
      ? {}
      : { delay: 300, distance: "0px", origin: "bottom" },
  },
  {
    element: ".hero-title",
    animation: prefersReducedMotion
      ? {}
      : { delay: 500, origin: window.innerWidth > 768 ? "left" : "bottom" },
  },
  {
    element: ".hero-cta",
    animation: prefersReducedMotion
      ? {}
      : { delay: 1000, origin: window.innerWidth > 768 ? "left" : "bottom" },
  },
  {
    element: ".about-wrapper__image",
    animation: prefersReducedMotion ? {} : { delay: 600, origin: "bottom" },
  },
  {
    element: ".about-wrapper__info",
    animation: prefersReducedMotion
      ? {}
      : { delay: 1000, origin: window.innerWidth > 768 ? "left" : "bottom" },
  },
  {
    element: ".project-wrapper__text",
    animation: prefersReducedMotion
      ? {}
      : { delay: 500, origin: window.innerWidth > 768 ? "left" : "bottom" },
  },
  {
    element: ".project-wrapper__image",
    animation: prefersReducedMotion
      ? {}
      : { delay: 1000, origin: window.innerWidth > 768 ? "right" : "bottom" },
  },
  {
    element: ".contact-wrapper",
    animation: prefersReducedMotion ? {} : { delay: 800, origin: "bottom" },
  },
];
